// @file vue composable to get user zoom detalis
import { captureException } from '@@/bits/error_tracker'
import { checkIfUserIsInZoomCollaborateMode } from '@@/bits/zoom'
import { useZoomRealtimeStore } from '@@/pinia/zoom_realtime'
import { storeToRefs } from 'pinia'
import type { Ref } from 'vue'
import { computed, onMounted, ref } from 'vue'

interface ZoomCollaborationDetails {
  isUserInZoomCollaborationMode: Ref<boolean>
  isZoomCollaborationGuest: Ref<boolean>
}

const useZoomCollaboration = (): ZoomCollaborationDetails => {
  const { isUserHostingZoomCollaboration } = storeToRefs(useZoomRealtimeStore())
  const isUserInZoomCollaborationMode = ref(false)
  const isZoomCollaborationGuest = computed(
    () => isUserHostingZoomCollaboration.value === true && !isUserHostingZoomCollaboration.value,
  )

  onMounted(() => {
    checkIfUserIsInZoomCollaborateMode()
      .then((result) => {
        isUserInZoomCollaborationMode.value = result
      })
      .catch((e) => {
        captureException(e)
      })
  })

  return {
    isUserInZoomCollaborationMode,
    isZoomCollaborationGuest,
  }
}

export default useZoomCollaboration
